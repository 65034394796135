@import "./variables.module.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "./overrides.scss";
html {
	/* GLOBAL STYLING */
	* {
		padding: 0;
		margin: 0;
		// debugg ghost elements
		// background: #000 !important;
		// color: #0f0 !important;
		// outline: solid #f00 1px !important;
	}

	/* BODY STYLING */
	body {
		font-family: "Roboto", sans-serif !important;
		background: $backgroundColor;
		// height: 100%;
		//divider styling

		//remove margin bottom of <h>
		.remove-mb {
			margin-bottom: 0;
		}

		.divider {
			background-color: $dividerColor;
			opacity: 0.5;
			margin-left: 0.9rem;
			width: 90%;
		}

		.full-width-divider {
			background-color: $dividerColor;
			margin-bottom: 1rem;
		}

		.button:disabled {
			background-color: transparent;
		}

		// QR border
		.qr-border {
			border: 5px solid $primaryBorder;
		}

		// full width
		.full-width {
			width: auto !important;
		}

		// full height
		.full-height {
			height: 100% !important;
		}
		//
		.select {
			width: 100% !important;
			select {
				width: 100% !important;
			}
		}

		.select-bg {
			background: $lightGreyColor;
		}

		// table page select dropdown
		.table-page-select {
			border: 2px solid $backgroundColor;
			border-radius: 5px;
			background-color: $primary;
		}

		// primary border radius 20px
		.primary-border-radius {
			border-radius: 20px;
		}

		// logo-height
		.logo-height {
			height: 100px;
		}
		// custom-font-sizes
		.font-size-1 {
			font-size: 0.875rem;
		}
		.font-size-2 {
			font-size: 0.5rem;
		}

		// for making text really small
		.ultra-small-font {
			font-size: 9px;
		}

		// cutomer border radius
		.add-border-radius {
			border-radius: 28px;
		}
	}

	footer {
		bottom: 0;
	}
}
