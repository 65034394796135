@import "styles/exports.module.scss";

.btn-text-black {
	color: $dark !important;
}
// icon button

.transparent-icon-button {
	border-color: transparent !important;
	background-color: transparent !important;
	border-radius: 50% !important;
}

.square-border {
	border-radius: 10px !important  ;
}

.circle-border {
	border-radius: 50% !important;
}
.custom-sizing {
	width: 14px !important;
	height: 22px !important;
}

.upgrade-button {
	border: none;
	border-radius: 13px;
	width: 4rem;
	height: 4rem;
	font-size: 0.5rem;
}

.active-btn {
	background-color: $infoColor;
}
