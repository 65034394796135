// declare colors variables here then export it in exports.modules.scss for accessing the variable across the project
$light: hsl(0, 0%, 100%);
$dark: hsl(0, 0%, 0%);
$lightGreyColor: hsl(0, 2%, 91%); //e8e7e7
$backgroundColor: hsl(0, 0%, 95%); // F2F2F2
$primaryColor: hsl(353, 49%, 78%); //E2ABB1
$infoColor: hsl(352, 80%, 90%); //FAD3D8;
$altBackgroundColor: hsl(350, 82%, 96%); //fdebee;
$primaryBorder: hsl(349, 100%, 75%); //FF8098
$altPrimaryColor: hsl(353, 49%, 78%); //E2ABB1
$successColor: hsl(78, 79%, 44%); //91C717
$dangerColor: hsl(354, 70%, 54%); //DC3545

$activeLinkColor: hsl(349, 100%, 75%); //FF8098;

$iconFilter: invert(82%) sepia(30%) saturate(6897%) hue-rotate(304deg)
	brightness(105%) contrast(103%);

$tableRowColorPrimary: hsl(352, 80%, 90%); //FAD3D8

$tableRowColorSecondary: hsl(0, 0%, 98%);
$borderColor: hsl(0, 0%, 0%);
$tableHeadBackground: hsl(0, 0%, 100%);
$fontColor: hsl(0, 0%, 0%);
$dividerColor: hsl(0, 0%, 67%);

// overriding bulma's variables
$primary: $primaryColor; //overriding bulma's primary color
$success: $successColor;
$danger: $dangerColor;
$info: $infoColor;
$body-background-color: $backgroundColor;
@import "bulma/bulma.sass";
