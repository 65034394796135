@import "./variables.module.scss";
// write all the exports here if you wish to use the color in js file
:export {
	backgroundColor: $backgroundColor;
	altBackgroundColor: $altBackgroundColor;
	light: $light;
	dark: $dark;
	lightGreyColor: $lightGreyColor;

	dividerColor: $dividerColor;
	primaryBorder: $primaryBorder;

	primaryColor: $primaryColor;
	altPrimaryColor: $altPrimaryColor;

	activeLinkColor: $activeLinkColor; // color for active link
	iconFilter: $iconFilter; // color for svg element visit https://codepen.io/eviilraj/pen/qBjebwJ in case of primary color change
	tableHeadBackground: $tableHeadBackground; // color for table head row
	tableRowColorPrimary: $tableRowColorPrimary; // primary color for table row
	tableRowColorSecondary: $tableRowColorSecondary; //secondary color for table row
	borderColor: $borderColor; // color for table row
	fontColor: $fontColor; // color for table row
}
